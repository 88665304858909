import axios from 'axios';

export default {
  getUserRegistrations(pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/UserRegistration/GetUserRegistrationsWithPagination', {
      params: {
        pageNumber, pageSize, sortField, sortDesc
      }
    });
  },
  checkRegistrationStatus(userName) {
    return axios.get('/api/UserRegistration/checkRegistrationStatus', {
      params: {
        userName 
      }
    });
  },
  getUserRegistration(id) {
    return axios.get('/api/UserRegistration/GetUserRegistration', {
      params: {
        id
      }
    });
  },
  getDispatchCenters() {
    return axios.get('/api/UserRegistration/getDispatchCenters');
  },
  createUserRegistration(registrationModel) {
    return axios.post('/api/UserRegistration/CreateUserRegistration', registrationModel);
  },
  createUser(userModel) {
    return axios.post('/api/UserRegistration/CreateUser', userModel);
  },
  rejectRegistration(id) {
    return axios.post('/api/UserRegistration/RejectRegistration', { id });
  },
};

