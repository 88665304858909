<template>
  <el-form :rules="rules" v-loading="loading" ref="form" :model="userModel" label-width="12rem">
    <el-link type="primary" :underline="false" class="mt-1"
             @click="$router.push('/administration/user-managements')"><i class="el-icon-back" /> Назад</el-link>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item prop="email" label="E-mail:">
          <el-input disabled v-model="userModel.email"></el-input>
        </el-form-item>
        <el-form-item prop="userName" label="Логин:">
          <el-input disabled v-model="userModel.userName"></el-input>
        </el-form-item>
        <el-form-item prop="roleGroupIds" label="Роль:">
          <el-input disabled aria-placeholder="RoleGroupName" placeholder="" v-model="userRoleGroups"></el-input>
        </el-form-item> 
        <el-form-item v-if="tempBlocking" label="Активен:" class="mb-0">
          <el-col :span="8"><el-checkbox value="false" readonly /> </el-col>
          <el-col :span="16" style="text-align: right; padding-right: 0px" >
            <el-link type="danger" :underline="false" class="ml-2" 
            >Временная блокировка</el-link></el-col>
        </el-form-item>
        <el-form-item v-else prop="isActive" label="Активен:" class="mb-0">
          <el-checkbox v-model="userModel.isActive" @change="isActiveCheckHandle" />
        </el-form-item>
        <el-form-item class="mt-0" label=""  v-if="!userModel.isActiveDirectory">
          <el-link type="primary" :underline="false" class="mt-1"
                   >Число попыток неправильного входа: {{userModel.loginAttempts}}</el-link>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item prop="organizationId" label="Организация:">
          <remote-search-input style="width: 100%"
                               v-model="userModel.organizationId"
                               searchUrl="/api/organizations/SearchByNameForUser"
                               size="large"
                               return-prop="id"
                               :start-length="3"
                               :initial-value="userModel.organizationName"
                               :allow-create="false"
                               :show-when-init="true" />
        </el-form-item>  
        <el-form-item prop="registrationOrganizationId" label="Диспетчерский центр:" v-if="!userModel.isActiveDirectory">
          <el-select v-model="userModel.registrationOrganizationId" filterable id="registration_org_id">
            <el-option v-for="item in dispatchCenters"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item class="mb-0" label=""  v-if="!userModel.isActiveDirectory">
          <el-checkbox v-model="needChangePassword" @change="needChangePasswordCheckHandle">Смена пароля</el-checkbox>
        </el-form-item>
        <el-form-item class="mb-0" prop="password" label="Новый пароль:"  v-if="!userModel.isActiveDirectory">
          <el-input :disabled="!needChangePassword" v-model="userModel.password"></el-input>
        </el-form-item>
        <el-form-item class="mt-3 mb-0" label=""  v-if="!userModel.isActiveDirectory">
          <el-link :disabled="!needChangePassword" type="primary" :underline="false" class="mt-1"
                   @click="generateRandomPassword" >Сгенерировать пароль</el-link>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="mt-0">
      <el-button
        :disabled="loading"
        class="login-button mt-4"
        type="primary"
        @click="handleUpdateUser"
      >Сохранить</el-button>
    </div>
  </el-form>
</template>

<script>
import manageApi from '@/api/administration/userManagement';
import regApi from '@/api/administration/userRegistration';
import identityApi from '@/api/identity';
import remoteSearchInput from '@/components/Ui/RemoteSearchInput';
import validationRules from '@/mixins/validationRules';
import moment from 'moment';

export default {
  name: 'userManagement',
  mixins: [validationRules],
  components: { remoteSearchInput },
  data() {
    return {
      dispatchCenters: [],
      rules: {
        organizationId: [
          {
            required: true,
            message: 'Необходимо выбрать организацию',
            trigger: 'blur',
          },
        ],
        registrationOrganizationId: [
          {
            required: true,
            message: 'Необходимо выбрать ДЦ',
            trigger: 'blur',
          },
        ],
        /* roleGroupIds: [
          {
            required: true,
            message: 'Необходимо выбрать как минимум одну роль',
            trigger: 'blur',
          },
        ], */
        password: [
          {
            required: false,
            message: 'Необходимо указать пароль',
            trigger: 'blur',
          },
          { validator: this.validatePassword, trigger: 'blur' }
        ],
      },
      loading: false,
      needChangePassword: false,
      userModel: {},
      roleGroups: [],
    };
  },
  async mounted() {
    if (this.$route.query.id) {
      this.loading = true;
      await this.getUserModel(this.$route.query.id);
      await this.getRoleGroups();
      await this.getDispatchCenters();
      this.loading = false;
    } else {
      // error
    }
  },
  computed: {
    userRoleGroups() {
      let result = '';
      if (!this.userModel || !this.userModel.roleGroupIds || !this.userModel.roleGroupIds.length) {
        return result;
      }
      for (let i = 0; i < this.userModel.roleGroupIds.length; i++) {
        if (i > 0) {
          result += '; ';
        }

        const roleGroup = this.roleGroups.find((t) => t.id === this.userModel.roleGroupIds[i]);
        if (roleGroup) {
          result += roleGroup.name;
        }
      }
      return result;
    },
    tempBlocking() {
      const moment1 = moment(this.userModel.tempLockUntil).utc();
      const moment2 = moment().utc().format('YYYY-MM-DD HH:mm:ss');
      return this.userModel.tempLockUntil ? moment(moment2).isBefore(moment1) : false;
    }
  },
  methods: {
    async isActiveCheckHandle(val) {
      if (val) {
        this.userModel.loginAttempts = 0;
      }
    },
    async getDispatchCenters() {
      const res = await regApi.getDispatchCenters();
      if (res.data) {
        this.dispatchCenters = res.data;
      }
    },
    async getUserModel(id) {
      const res = await manageApi.GetUserManagement(id);
      this.userModel = res.data;
      this.$set(this.userModel, 'password', null);
    },
    async getRoleGroups() {
      const res = await identityApi.getRoleGroups();
      this.roleGroups = res.data.items;
    },
    async generateRandomPassword() {
      const res = await identityApi.generateRandomPassword();
      this.userModel.password = res.data;
    },
    async handleUpdateUser() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      let message = '<span>Вы действительно хотите изменить данные пользователя?</span>';
      if (!this.userModel.isActive) message += '<br /><br /><span style="color: red">Внимание! Учетная запись пользователя будет деактивирована!</span>';
      if (this.needChangePassword) message += '<br /><br /><span style="color: red">Внимание! Будет произведена смена пароля пользователя!</span>';

      this.$confirm(message,
        'Подтверждение', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        await this.updateUser();
      });
    },
    async updateUser() {
      this.loading = true;
      const response = await manageApi.updateUser({
        id: this.userModel.id,
        email: this.userModel.email,
        needChangePassword: this.needChangePassword,
        password: this.needChangePassword ? this.userModel.password : null,
        isActive: this.userModel.isActive,
        organizationId: this.userModel.organizationId,
        registrationOrganizationId: this.userModel.registrationOrganizationId,
        loginAttempts: this.userModel.loginAttempts,
        // roleGroupIds: this.userModel.roleGroupIds,
      });

      if (response && response.data) {
        if (!response.data.succeeded) {
          this.$alert(response.data.resultMessage, 'Изменение данных пользователя', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.$alert(response.data.resultMessage, 'Изменение данных пользователя', {
            confirmButtonText: 'OK',
            type: 'success',
            customClass: 'alert-30',
            showClose: false,
            // callback: this.userUpdateCallback
          });
        }
      }

      this.loading = false;
    },
    /* userUpdateCallback() {
      this.userUpdateComplete = true;
    }, */
    needChangePasswordCheckHandle(newVal) {
      this.rules.password[0].required = newVal;

      if (!newVal) this.userModel.password = null;
    }
  }
};
</script>

<style scoped>

</style>
